import * as React from "react";
import Layout from "../../layout";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import { RichText } from "prismic-reactjs";

// markup
const TC = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicTc {
        nodes {
          data {
            t_c_text {
              richText
            }
          }
        }
        edges {
          node {
            data {
              t_c_text {
                richText
              }
            }
          }
        }
      }
    }
  `);

  const tc = get(data, "allPrismicTc.nodes", []);

  const tcText = tc[0].data.t_c_text.richText;

  return (
    <Layout
      title={"Terms & Conditions"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
      publicPage
    >
      <section className="register py-8 py-lg-16">
        <article className="container row px-8 mx-auto">
          {RichText.render(tcText)}
        </article>
      </section>
    </Layout>
  );
};

export default TC;
